import { template as template_8a59ec36eff742df938dd9cc09573a56 } from "@ember/template-compiler";
const FKLabel = template_8a59ec36eff742df938dd9cc09573a56(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
